<script>
import {
  required,
  email,
  minLength,
  sameAs,
  maxLength,
  minValue,
  maxValue,
  numeric,
  url,
  alphaNum,
} from "vuelidate/lib/validators";
import appConfig from "@/app.config";

import Vue from 'vue'


/**
 * Register component
 */
export default {
  page: {
    title: "Register",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
 data() {
    return {
      user: {
        username: "",
        emailID: "",
        mobileNo: "",
        password: "",
        confirmPassword: "",
        corporateCode: "",
      },
      submitted: false,
      tryingToRegister: false,
      isRegisterError: false,
      title: "Register",
    };
  },
  validations: {
    user: {
      username: {
        required,
      },
      emailID: {
        required,
        email,
      },
      mobileNo: {
        required,
        numeric,
        minLength: minLength(10),
        maxLength: maxLength(10),
      },
      password: {
        required,
        minLength: minLength(6),
      },
      confirmPassword: {
        required,
        sameAsPassword: sameAs("password"),
      },
    },
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
    notificationAutoCloseDuration() {
      return this.$store && this.$store.state.notification ? 5 : 0;
    },
  },
  mounted() {
    document.body.classList.add("authentication-bg");
  },
  methods: {
    // Try to register the user in with the email, username
    // and password they provided.
    tryToRegisterIn() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
          this.tryingToRegister = true;
          this.regError = null;
           if(this.corporateCode != '')
           {
					    var corporateCodeData =  this.corporateCode;
           }
           else
           {
					    var corporateCodeData =  '';
           }
          // APi for registering users
           let jsonUserData = JSON.stringify({ userName: this.username,
                                              emailID: this.emailID,
                                              mobileNo: this.mobileNo,
                                              corporateCode: corporateCodeData,
                                              devicetype: '',
                                              androidtoken: '',
                                              iostoken: ''
                                            });
         
					Vue.axios.post('/_registerUser', jsonUserData)
					.then((response)=>{
						console.log(response);
            // this.$router.push('/');
					
					}).catch((error) => {
				            console.log(error.response.data.message);
				    });

        // if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
        //   this.tryingToRegister = true;
        //   // Reset the regError if it existed.
        //   this.regError = null;
        //   return (
        //     this.$store
        //       .dispatch("auth/register", {
        //         email: this.user.email,
        //         password: this.user.password,
        //       })
        //       // eslint-disable-next-line no-unused-vars
        //       .then((token) => {
        //         this.tryingToRegister = false;
        //         this.isRegisterError = false;
        //         this.registerSuccess = true;
        //         if (this.registerSuccess) {
        //           this.$router.push(
        //             this.$route.query.redirectFrom || {
        //               path: "/",
        //             }
        //           );
        //         }
        //       })
        //       .catch((error) => {
        //         this.tryingToRegister = false;
        //         this.regError = error ? error : "";
        //         this.isRegisterError = true;
        //       })
        //   );
        // } else if (process.env.VUE_APP_DEFAULT_AUTH === "fakebackend") {
        //   const { email, username, password } = this.user;
        //   if (email && username && password) {
        //     this.$store.dispatch("authfack/registeruser", this.user);
        //   }
        // }
      }
    },
  },
};
</script>

<template>
  <div id="register_bg">
    <div id="register">
		<aside>
			<figure>
				<a href="index.html"><img src="../../templateAssests/img/cklogo.png" style="object-fit:cover;" width="148" height="50" alt=""></a>
			</figure>
			<div class="access_social">
					<a href="#0" class="social_bt facebook">Register with Facebook</a>
					<a href="#0" class="social_bt google">Register with Google</a>
				</div>
            <div class="divider"><span>Or</span></div>
            <div
                    v-if="notification.message"
                    :class="'alert ' + notification.type"
                  >
                    {{ notification.message }}
                  </div>

                  <b-form @submit.prevent="tryToRegisterIn">
                    <b-form-group
                      id="fullname-group"
                      class="mb-3"
                    >
                      <b-form-input
                        id="username"
                        v-model="user.username"
                        type="text"
                        placeholder="Full Name*"
                        :class="{
                          'is-invalid': submitted && $v.user.username.$error,
                        }"
                      ></b-form-input>
              					<i class="icon_pencil-edit"></i>

                      <div
                        v-if="submitted && !$v.user.username.required"
                        class="invalid-feedback"
                      >
                        Full is required.
                      </div>
                    </b-form-group>

                    <b-form-group
                      id="email-group"
                      class="mb-3"
                    >
                      <b-form-input
                        id="email"
                        v-model="user.emailID"
                        type="email"
                        placeholder="Email*"
                        :class="{
                          'is-invalid': submitted && $v.user.emailID.$error,
                        }"
                      ></b-form-input>
              					<i class="icon_mail_alt"></i>
                      <div
                        v-if="submitted && $v.user.emailID.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.user.emailID.required"
                          >Email is required.</span
                        >
                        <span v-if="!$v.user.emailID.email"
                          >Please enter valid email.</span
                        >
                      </div>
                    </b-form-group>

                    <b-form-group
                      id="contact-group"
                      class="mb-3"
                    >
                      <b-form-input
                        id="mobileNo"
                        v-model="user.mobileNo"
                        type="text"
                        placeholder="Mobile Number*"
                        :class="{
                          'is-invalid': submitted && $v.user.mobileNo.$error,
                        }"
                      ></b-form-input>
              					<i class="icon_mobile"></i>
                      <div
                        v-if="submitted && $v.user.mobileNo.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.user.mobileNo.required"
                          >Mobile Number is required.</span
                        >
                        <span v-if="!$v.user.mobileNo.numeric"
                          >Please enter valid Mobile Number.</span
                        >
                        <span v-if="!$v.user.mobileNo.maxLength"
                      >Mobile number should not be greater than 10 digits.</span
                    >
                     <span v-if="!$v.user.mobileNo.minLength"
                      >Mobile number should be 10 digits.</span
                    >
                      </div>
                    </b-form-group>

                    <b-form-group
                      id="password-group"
                      class="mb-3"
                    >
                      <b-form-input
                        id="password"
                        v-model="user.password"
                        type="password"
                        placeholder="Password*"
                        :class="{
                          'is-invalid': submitted && $v.user.password.$error,
                        }"
                      ></b-form-input>
              					<i class="icon_lock_alt"></i>
                      <div
                        v-if="submitted && !$v.user.password.required"
                        class="invalid-feedback"
                      >
                        Password is required.
                      </div>
                    </b-form-group>

                    <b-form-group
                      id="confirm-password-group"
                      class="mb-3"
                    >
                      <b-form-input
                        id="confirmPassword"
                        v-model="user.confirmPassword"
                        type="password"
                        placeholder="Confirm Password*"
                        :class="{
                          'is-invalid': submitted && $v.user.confirmPassword.$error,
                        }"
                      ></b-form-input>
              					<i class="icon_lock_alt"></i>
                      <div
                        v-if="submitted && $v.user.confirmPassword.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.user.confirmPassword.required"
                          >Confirm Password is required.</span
                        >
                        <span
                      v-else-if="!$v.user.confirmPassword.sameAsPassword"
                      >Confirm Password should be the same as password.</span
                    >
                      </div>
                    </b-form-group>

                    <b-form-group
                      id="corporate-group"
                      class="mb-3"
                    >
                      <b-form-input
                        id="corporateCode"
                        v-model="user.corporateCode"
                        type="password"
                        placeholder="Coreporate Code (optional)"
                      ></b-form-input>
              					<i class="icon_building"></i>
                    </b-form-group>
                    <!-- <div class="form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="auth-terms-condition-check"
                      />
                      <label
                        class="form-check-label"
                        for="auth-terms-condition-check"
                        >I accept
                        <a href="javascript: void(0);" class="text-dark"
                          >Terms and Conditions</a
                        ></label
                      >
                    </div> -->
                    <div class="mt-3 text-end">
                      <b-button type="submit" variant="primary" class="btn_1 gradient full-width"
                        >Register</b-button
                      >
                    </div>
			          	<div class="text-center mt-2"><small>Already have an acccount? <strong><a href="/login">Sign In</a></strong></small></div>
                  </b-form>
			
			<div class="copy">© 2021 Cloud Kitch</div>
		</aside>
	</div>
	<!-- /login -->
  </div>
</template>

<style lang="scss" module></style>

<!-- SPECIFIC CSS -->
<style scoped src="../../templateAssests/css/order-sign_up.css"></style>
<style scoped>
.a.btn_1:hover, .btn_1:hover{
      color:#fff;
    }
</style>
