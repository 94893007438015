import { render, staticRenderFns } from "./register.vue?vue&type=template&id=69728674&scoped=true&"
import script from "./register.vue?vue&type=script&lang=js&"
export * from "./register.vue?vue&type=script&lang=js&"
import style1 from "../../templateAssests/css/order-sign_up.css?vue&type=style&index=1&id=69728674&scoped=true&lang=css&"
import style2 from "./register.vue?vue&type=style&index=2&id=69728674&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69728674",
  null
  
)

export default component.exports