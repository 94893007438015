var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"register_bg"}},[_c('div',{attrs:{"id":"register"}},[_c('aside',[_vm._m(0),_vm._m(1),_vm._m(2),(_vm.notification.message)?_c('div',{class:'alert ' + _vm.notification.type},[_vm._v(" "+_vm._s(_vm.notification.message)+" ")]):_vm._e(),_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.tryToRegisterIn($event)}}},[_c('b-form-group',{staticClass:"mb-3",attrs:{"id":"fullname-group"}},[_c('b-form-input',{class:{
                          'is-invalid': _vm.submitted && _vm.$v.user.username.$error,
                        },attrs:{"id":"username","type":"text","placeholder":"Full Name*"},model:{value:(_vm.user.username),callback:function ($$v) {_vm.$set(_vm.user, "username", $$v)},expression:"user.username"}}),_c('i',{staticClass:"icon_pencil-edit"}),(_vm.submitted && !_vm.$v.user.username.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Full is required. ")]):_vm._e()],1),_c('b-form-group',{staticClass:"mb-3",attrs:{"id":"email-group"}},[_c('b-form-input',{class:{
                          'is-invalid': _vm.submitted && _vm.$v.user.emailID.$error,
                        },attrs:{"id":"email","type":"email","placeholder":"Email*"},model:{value:(_vm.user.emailID),callback:function ($$v) {_vm.$set(_vm.user, "emailID", $$v)},expression:"user.emailID"}}),_c('i',{staticClass:"icon_mail_alt"}),(_vm.submitted && _vm.$v.user.emailID.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.user.emailID.required)?_c('span',[_vm._v("Email is required.")]):_vm._e(),(!_vm.$v.user.emailID.email)?_c('span',[_vm._v("Please enter valid email.")]):_vm._e()]):_vm._e()],1),_c('b-form-group',{staticClass:"mb-3",attrs:{"id":"contact-group"}},[_c('b-form-input',{class:{
                          'is-invalid': _vm.submitted && _vm.$v.user.mobileNo.$error,
                        },attrs:{"id":"mobileNo","type":"text","placeholder":"Mobile Number*"},model:{value:(_vm.user.mobileNo),callback:function ($$v) {_vm.$set(_vm.user, "mobileNo", $$v)},expression:"user.mobileNo"}}),_c('i',{staticClass:"icon_mobile"}),(_vm.submitted && _vm.$v.user.mobileNo.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.user.mobileNo.required)?_c('span',[_vm._v("Mobile Number is required.")]):_vm._e(),(!_vm.$v.user.mobileNo.numeric)?_c('span',[_vm._v("Please enter valid Mobile Number.")]):_vm._e(),(!_vm.$v.user.mobileNo.maxLength)?_c('span',[_vm._v("Mobile number should not be greater than 10 digits.")]):_vm._e(),(!_vm.$v.user.mobileNo.minLength)?_c('span',[_vm._v("Mobile number should be 10 digits.")]):_vm._e()]):_vm._e()],1),_c('b-form-group',{staticClass:"mb-3",attrs:{"id":"password-group"}},[_c('b-form-input',{class:{
                          'is-invalid': _vm.submitted && _vm.$v.user.password.$error,
                        },attrs:{"id":"password","type":"password","placeholder":"Password*"},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}}),_c('i',{staticClass:"icon_lock_alt"}),(_vm.submitted && !_vm.$v.user.password.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Password is required. ")]):_vm._e()],1),_c('b-form-group',{staticClass:"mb-3",attrs:{"id":"confirm-password-group"}},[_c('b-form-input',{class:{
                          'is-invalid': _vm.submitted && _vm.$v.user.confirmPassword.$error,
                        },attrs:{"id":"confirmPassword","type":"password","placeholder":"Confirm Password*"},model:{value:(_vm.user.confirmPassword),callback:function ($$v) {_vm.$set(_vm.user, "confirmPassword", $$v)},expression:"user.confirmPassword"}}),_c('i',{staticClass:"icon_lock_alt"}),(_vm.submitted && _vm.$v.user.confirmPassword.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.user.confirmPassword.required)?_c('span',[_vm._v("Confirm Password is required.")]):(!_vm.$v.user.confirmPassword.sameAsPassword)?_c('span',[_vm._v("Confirm Password should be the same as password.")]):_vm._e()]):_vm._e()],1),_c('b-form-group',{staticClass:"mb-3",attrs:{"id":"corporate-group"}},[_c('b-form-input',{attrs:{"id":"corporateCode","type":"password","placeholder":"Coreporate Code (optional)"},model:{value:(_vm.user.corporateCode),callback:function ($$v) {_vm.$set(_vm.user, "corporateCode", $$v)},expression:"user.corporateCode"}}),_c('i',{staticClass:"icon_building"})],1),_c('div',{staticClass:"mt-3 text-end"},[_c('b-button',{staticClass:"btn_1 gradient full-width",attrs:{"type":"submit","variant":"primary"}},[_vm._v("Register")])],1),_c('div',{staticClass:"text-center mt-2"},[_c('small',[_vm._v("Already have an acccount? "),_c('strong',[_c('a',{attrs:{"href":"/login"}},[_vm._v("Sign In")])])])])],1),_c('div',{staticClass:"copy"},[_vm._v("© 2021 Cloud Kitch")])],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('figure',[_c('a',{attrs:{"href":"index.html"}},[_c('img',{staticStyle:{"object-fit":"cover"},attrs:{"src":require("../../templateAssests/img/cklogo.png"),"width":"148","height":"50","alt":""}})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"access_social"},[_c('a',{staticClass:"social_bt facebook",attrs:{"href":"#0"}},[_vm._v("Register with Facebook")]),_c('a',{staticClass:"social_bt google",attrs:{"href":"#0"}},[_vm._v("Register with Google")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"divider"},[_c('span',[_vm._v("Or")])])}]

export { render, staticRenderFns }